import * as React from 'react'
import { ModalFooter } from '@components/modals/modal-footer'
import { useFormRequest } from '@hooks/use-api-request'
import { ModalBody } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { CustomReactSelect } from '@components/custom-react-select'
import { useDispatch, useSelector } from 'react-redux'
import { CashLeftOnVoucher } from '@modules/reservations/pending/add-gastro-card-modal/cash-left-on-voucher'
import { gastroCardsOptionsSelector } from '@store/selectors/reservations'
import { assignGastroCard } from '@api/reservation'
import { GastroCardSelectOption } from '@models/reservation'
import { formatPrice } from '@helpers/price-helper'
import { setReservationDetails } from '@store/slices/reservations-slice'
import Decimal from 'decimal.js'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { parseISODate } from '@helpers/date-helper'
import { isAfter, isBefore, isSameDay } from 'date-fns'

const SELECTABLE_GASTRO_KINDS = [
  'normal',
  'wholesale',
  'gastro_card_2024',
  'gastro_card_2024_wholesale',
  'gastro_card_2025',
  'gastro_card_2025_wholesale',
  'gastro_card_boost',
  'gastro_card_boost_wholesale',
  'gastro_card_2026',
  'gastro_card_2027',
]

interface AddGastroCardModalProps {
  url: string
  handleClose: () => void
}

interface FormInputs {
  gastro_card: GastroCardSelectOption
}

export const AddGastroCardModal = ({ url, handleClose }: AddGastroCardModalProps): JSX.Element => {
  const reservationDetails = useSelector(selectReservationDetails)
  const gastroCards = useSelector(gastroCardsOptionsSelector)

  const methods = useForm<FormInputs>({ defaultValues: { gastro_card: undefined } })

  const hasError = !methods.formState.isValid

  const selectedCard = methods.watch('gastro_card')

  const dispatch = useDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(
    async ({ gastro_card }: FormInputs) => {
      dispatch(setReservationDetails(await assignGastroCard(url, gastro_card.code)))
      handleClose()
    },
    methods.setError,
    { nonFieldErrorsAs: 'gastro_card' },
  )

  const customSelectStyles = React.useMemo(
    () => (hasError ? { control: { borderColor: 'red' } } : undefined),
    [hasError],
  )

  const gastroCardOptions = React.useMemo(() => {
    if (!reservationDetails) return []

    const isAlreadyAdded = gastroCard => reservationDetails?.wallets.some(wallet => wallet.code === gastroCard.code)
    const isEmpty = gastroCard => new Decimal(gastroCard.available_amount).lte(0)
    const isSelectable = gastroCard => SELECTABLE_GASTRO_KINDS.includes(gastroCard.kind)
    const dateFrom = parseISODate(reservationDetails.date_from) as Date
    const isInRange = gastroCard =>
      (isAfter(dateFrom, parseISODate(gastroCard.active_after) as Date) ||
        isSameDay(dateFrom, parseISODate(gastroCard.active_after) as Date)) &&
      (isBefore(dateFrom, parseISODate(gastroCard.expire_after) as Date) ||
        isSameDay(dateFrom, parseISODate(gastroCard.expire_after) as Date))

    return gastroCards.filter(
      gastroCard =>
        !isAlreadyAdded(gastroCard) && !isEmpty(gastroCard) && isSelectable(gastroCard) && isInRange(gastroCard),
    )
  }, [reservationDetails?.wallets, reservationDetails?.date_from, gastroCards])

  const formatOptionLabel = ({ label, available_amount }) => (
    <div className="text-darker-gray">
      <span className="fw-bold font-size-12 me-2 letter-spacing-2">{label}</span>
      <div className="d-xl-block d-inline-block mt-1 mt-xl-0">
        <span className="font-size-11 fw-lighter me-2">Środki do wykorzystania:</span>
        <span className="font-size-11 fw-bold">{formatPrice(available_amount)}</span>
      </div>
    </div>
  )

  return (
    <>
      <ModalBody className="gastro-card__modal-body">
        <FormProvider {...methods}>
          <p className="text-muted font-size-sm">
            Jeśli chcesz skorzystać z promocji gastronomicznej wybierz dostępnych kod:
          </p>
          <CustomReactSelect
            inputName="gastro_card"
            options={gastroCardOptions}
            placeholder="Wybierz kod"
            customStyles={customSelectStyles}
            isSearchable={false}
            formatOptionLabel={formatOptionLabel}
            noOptionsMessage={() => 'Brak dostępnych voucherów'}
          />
          <div className="mt-2">{!hasError && selectedCard && <CashLeftOnVoucher gastroCard={selectedCard} />}</div>
        </FormProvider>
      </ModalBody>
      <ModalFooter
        submitLabel="Dodaję do rezerwacji"
        onSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
        withCancelButton
        buttonDisabled={!selectedCard || hasError}
        onClose={handleClose}
        saveButtonVariant={TrapezeButtonVariant.secondary}
      />
    </>
  )
}

import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { ButtonLoading } from '@components/controls/button-loading'
import { useModal } from '@components/modals/use-modal'
import { GastroCard } from '@models/client'
import { commonObjectPost } from '@api/basic-requests'
import { useApiRequest } from '@hooks/use-api-request'
import { GastroCardAgreements } from '@modules/special-products/gastro-vouchers/gastro-voucher-agreements'
import { SpecialProductRowPaymentButton } from '@modules/special-products/special-product-row-payment-button'
import { useConfirmation } from '@hooks/use-confirmation'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { updateClientGastroCard } from '@store/actions/client-actions'
import { getGastroProductName, isGastroGenericProduct } from '@modules/special-products/gastro-vouchers/utils'

interface Props {
  className?: string
  voucher: GastroCard
}

export const GastroVoucherPaymentButton = ({ className, voucher }: Props): JSX.Element | null => {
  const { showSuccess } = useConfirmation()
  const dispatch = useDispatch()

  const productName = voucher && getGastroProductName(voucher)

  const { action: handleRulesSubmit, isLoading } = useApiRequest(async agreements => {
    await commonObjectPost(voucher.urls.rules, agreements)
    dispatch(updateClientGastroCard({ ...voucher, has_rules_to_accept: false }))

    hideGastroCouponRulesModal()
    showSuccess({
      variant: 'success',
      action: RulesAcceptedAction,
      title: 'Gotowe',
      subtitle: `Voucher "${productName}" będzie gotowy do użycia po zaksięgowaniu wpłaty.`,
    })
  })

  const [showGastroCouponRulesModal, hideGastroCouponRulesModal] = useModal('GastroCouponRulesModal', {
    title: `Regulaminy vouchera "${productName}"`,
    onSubmit: handleRulesSubmit,
    voucher,
  })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    showGastroCouponRulesModal()
  }

  const isOnlyGastroCouponRulesRequired = isGastroGenericProduct(voucher.kind) && voucher.payment_method === 'cash_card'

  if (isOnlyGastroCouponRulesRequired && !voucher.has_rules_to_accept) return null

  return isOnlyGastroCouponRulesRequired ? (
    <ButtonLoading
      className={clsx(
        'd-flex justify-content-center h-fit-content py-2 px-4 px-sm-5 mt-3 responsiveness-button',
        className,
      )}
      variant="danger"
      onClick={handleClick}
      isLoading={isLoading}
      loadingClassName="fw-medium"
      loadingLabel="Proszę czekać"
    >
      <IconWithText
        className="text-white"
        iconClassName="uil-file-landscape-alt me-2 py-1 font-size-xxl lh-1"
        textClassName="fw-medium font-size-md text-nowrap"
        text="Zaakceptuj zgody"
      />
    </ButtonLoading>
  ) : (
    <SpecialProductRowPaymentButton
      className="mt-3 mt-sm-0 responsiveness-button"
      amount={voucher.required_payment}
      productUrls={voucher.urls}
      paymentProviders={voucher.payment_providers}
      agreements={({ paymentMethod, onProductAgreementsChange }) => (
        <GastroCardAgreements
          gastroCard={voucher}
          paymentMethod={paymentMethod}
          onProductAgreementsChange={onProductAgreementsChange}
        />
      )}
    />
  )
}

const RulesAcceptedAction = ({ onClose }) => (
  <Button
    variant="primary"
    onClick={onClose}
    className="mx-auto text-white px-5 btn-tall responsiveness-button mt-3 font-weight-semibold"
  >
    Gotowe
  </Button>
)

import * as React from 'react'
import { SpecialProductCard } from '@modules/special-products/special-product-card'
import { SingleGastroVoucher } from '@modules/special-products/gastro-vouchers/single-gastro-voucher'
import { GastroCard } from '@models/client'
import { sortObjectListByDate } from '@helpers/utils'

interface Props {
  gastroCards: GastroCard[]
  title: string
}

export const GastroVouchers = ({ gastroCards, title }: Props): JSX.Element | null => {
  const groupedVouchers = getGroupedVouchers(gastroCards)

  const sortedVouchers = React.useMemo(
    () => [
      ...sortObjectListByDate(groupedVouchers.confirmed, 'sell_date'),
      ...sortObjectListByDate(groupedVouchers.initial, 'sell_date'),
      ...sortObjectListByDate(groupedVouchers.completed, 'sell_date'),
    ],
    [groupedVouchers],
  )

  if (!sortedVouchers.length) return null

  return (
    <SpecialProductCard title={title}>
      {sortedVouchers.map((voucher: GastroCard) => (
        <SingleGastroVoucher key={voucher.id} voucher={voucher} />
      ))}
    </SpecialProductCard>
  )
}

interface GroupedVouchers {
  initial: GastroCard[]
  confirmed: GastroCard[]
  completed: GastroCard[]
}

const getGroupedVouchers = (gastroCards: GastroCard[]): GroupedVouchers =>
  gastroCards.reduce(
    (acc, voucher: GastroCard) => {
      if (voucher.status === 'initial') {
        return { ...acc, initial: [...acc.initial, voucher] }
      }

      if (voucher.status === 'confirmed') {
        return { ...acc, confirmed: [...acc.confirmed, voucher] }
      }

      if (voucher.status === 'completed') {
        return { ...acc, completed: [...acc.completed, voucher] }
      }

      return acc
    },
    { initial: [], confirmed: [], completed: [] },
  )

export const GastroCardStatusesToShow = ['initial', 'confirmed', 'completed']

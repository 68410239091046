import * as React from 'react'
import { SubscriptionListRow } from '@modules/special-products/subscriptions/subscription-list-row'
import { SubscriptionCode, SubscriptionType } from '@models/client'

interface Props {
  codes: SubscriptionCode[]
  subscriptionType: SubscriptionType
}

export const SubscriptionList = ({ codes, subscriptionType }: Props): JSX.Element => {
  const groupedCodes = getGroupedCodes(codes)
  const sortedCodes = [
    ...groupedCodes.activeSplit,
    ...groupedCodes.activeNotSplit,
    ...groupedCodes.usedSplit,
    ...groupedCodes.usedNotSplit,
  ]

  return (
    <div className="my-2">
      <div className="d-flex px-3 font-size-sm text-light-gray pb-1">
        <span className="col-sm-3">Status kodu:</span>
        <span className="col-sm-4">Kod pakietowy:</span>
        <span className="col-sm-3">Do wykorzystania:</span>
        <span className="col-sm-3">Ważny do:</span>
        <span className="col-sm-2" />
      </div>
      <div className="bg-light-blue rounded border px-3 d-flex flex-column">
        {sortedCodes.map((code: SubscriptionCode, index) => (
          <SubscriptionListRow
            key={code.id}
            code={code}
            borderless={index + 1 === codes.length}
            subscriptionType={subscriptionType}
          />
        ))}
      </div>
    </div>
  )
}

interface GroupedCodes {
  activeSplit: SubscriptionCode[]
  activeNotSplit: SubscriptionCode[]
  usedSplit: SubscriptionCode[]
  usedNotSplit: SubscriptionCode[]
}

export const getGroupedCodes = (codes: SubscriptionCode[]): GroupedCodes =>
  codes.reduce(
    (acc, code) => {
      if (!code.is_used && code.is_splitted) {
        return { ...acc, activeSplit: [...acc.activeSplit, code] }
      }
      if (!code.is_used && !code.is_splitted) {
        return { ...acc, activeNotSplit: [...acc.activeNotSplit, code] }
      }
      if (code.is_used && code.is_splitted) {
        return { ...acc, usedSplit: [...acc.usedSplit, code] }
      }
      return { ...acc, usedNotSplit: [...acc.usedNotSplit, code] }
    },
    { activeSplit: [], activeNotSplit: [], usedSplit: [], usedNotSplit: [] },
  )

import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { Button } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import Decimal from 'decimal.js'
import { useModal } from '@components/modals/use-modal'
import { Reservation } from '@models/reservation'
import { CouponConfiguration } from '@modules/coupons/coupon-configuration'
import { CouponTypeConfiguration } from '@models/coupons'

const BONUS_MULTIPLIER = 2

export interface CouponItemFormInputs {
  amount: number | string
  type: number
}

interface Props {
  reservation: Reservation
  isInHoursRange: boolean
  configuration: CouponTypeConfiguration | null
}

export const CouponCheckedInReservation: React.FC<Props> = ({ reservation, isInHoursRange, configuration }) => {
  const [showPurchaseModal] = useModal('CouponPurchaseModal')

  const { watch, handleSubmit } = useFormContext<CouponItemFormInputs>()

  const amount = watch('amount')
  const bonusAmount = amount ? new Decimal(amount).mul(BONUS_MULTIPLIER).toString() : '0'

  const handleShowPurchaseModal = (payload: CouponItemFormInputs) => {
    showPurchaseModal(null, {
      title: 'Zakup kuponów',
      couponData: payload,
      bonusAmount,
      reservation,
    })
  }

  const isCorrectValue = !!amount && typeof amount === 'number' && amount % 5 === 0

  return (
    <div className="col-12 col-md-8 mx-auto position-relative">
      <form onSubmit={handleSubmit(handleShowPurchaseModal)} noValidate>
        <div className="coupons-view__action-box__transaction-information">
          <strong>Pamiętaj: </strong>
          <span>1 kupon = 1 transakcja</span>
        </div>
        <CouponConfiguration bonusAmount={isCorrectValue ? bonusAmount : '0'} resortId={reservation.resort_id} />
        <div className="coupons-view__action-box__wrapper">
          <div className={clsx('coupons-view__action-box__content is-button', { 'is-gray': !isInHoursRange })}>
            {isInHoursRange ? (
              <Button
                variant="danger"
                type="submit"
                className="w-100 h-100 d-flex justify-content-center"
                disabled={!isCorrectValue}
              >
                <IconWithText
                  text="Przejdź do płatności"
                  iconClassName="uil-check font-size-xxl me-2"
                  className="text-white fw-bold coupons-view__payment-text"
                />
              </Button>
            ) : (
              <span className="text-white pt-2 pb-3">
                Zakup kuponu dostępny w godzinach {configuration?.purchase_hour_from} -{' '}
                {configuration?.purchase_hour_to}
              </span>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

import * as React from 'react'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectGet } from '@api/basic-requests'
import { ContentLoader } from '@components/loaders/content-loader'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { Subscription, SubscriptionDetails } from '@models/client'
import { SpecialProductDetailsHeader } from '@modules/special-products/special-product-details-header'
import { SubscriptionDetailsBaseInfo } from '@modules/special-products/subscriptions/details/subscription-details-base-info'
import { SubscriptionDetailsCodesList } from '@modules/special-products/subscriptions/details/subscription-details-codes-list'
import { SpecialProductRowPaymentButton } from '@modules/special-products/special-product-row-payment-button'
import * as clsx from 'clsx'
import { getGroupedCodes } from '@modules/special-products/subscriptions/subscription-list'
import { SubscriptionAgreements } from '@modules/special-products/subscriptions/subscription-agreements'
import { getSubscriptionName } from '@modules/special-products/subscriptions/utils'

interface Params {
  id: string
}

export const SubscriptionDetailsView = (): JSX.Element => {
  const { id } = useParams<Params>()

  const { subscriptions } = useSelector(selectClientDetails)

  const [subscriptionDetails, setSubscriptionDetails] = React.useState<SubscriptionDetails | null>(null)

  const { action: fetchDetails, isLoading } = useApiRequest(async () => {
    const subscription = subscriptions.find(
      (singleSubscription: Subscription) => singleSubscription.id === parseInt(id, 10),
    )

    if (!subscription) return
    setSubscriptionDetails(await commonObjectGet(subscription.urls.details))
  })

  React.useEffect(() => {
    fetchDetails()
  }, [subscriptions])

  const isInitial = subscriptionDetails?.status === 'initial'

  const groupedCodes = getGroupedCodes(subscriptionDetails?.codes ?? [])
  const sortedCodes = [
    ...groupedCodes.activeSplit,
    ...groupedCodes.activeNotSplit,
    ...groupedCodes.usedSplit,
    ...groupedCodes.usedNotSplit,
  ]

  return (
    <ContentLoader isLoading={isLoading}>
      {subscriptionDetails && (
        <>
          <SpecialProductDetailsHeader
            title="Twoje pakiety prywatne"
            productName={getSubscriptionName(subscriptionDetails)}
            productNumber={subscriptionDetails.number}
          />
          <SubscriptionDetailsBaseInfo subscriptionDetails={subscriptionDetails} />
          {subscriptionDetails.status === 'initial' && (
            <SpecialProductRowPaymentButton
              className="w-100 mt-5"
              productUrls={subscriptionDetails.urls}
              amount={subscriptionDetails.required_payment}
              agreements={({ paymentMethod, onProductAgreementsChange }) => (
                <SubscriptionAgreements
                  subscription={subscriptionDetails}
                  paymentMethod={paymentMethod}
                  onProductAgreementsChange={onProductAgreementsChange}
                />
              )}
            />
          )}
          <div className={clsx({ 'opacity-25 pe-none': isInitial })}>
            <SubscriptionDetailsCodesList codes={sortedCodes} subscriptionType={subscriptionDetails.type} />
          </div>
        </>
      )}
    </ContentLoader>
  )
}

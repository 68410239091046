import * as React from 'react'
import { SpecialProductCard } from '@modules/special-products/special-product-card'
import { SingleSubscription } from '@modules/special-products/subscriptions/single-subscription'
import { Subscription } from '@models/client'
import { sortObjectListByDate } from '@helpers/utils'

interface GroupedSubscriptions {
  initial: Subscription[]
  completed: Subscription[]
  confirmed: Subscription[]
}

interface Props {
  subscriptions: Subscription[]
}

export const Subscriptions = ({ subscriptions }: Props): JSX.Element => {
  const groupedSubscriptions = groupSubscription(subscriptions)

  const sorted = [
    ...sortObjectListByDate(groupedSubscriptions.confirmed, 'created'),
    ...sortObjectListByDate(groupedSubscriptions.initial, 'created'),
    ...groupedSubscriptions.completed,
  ]

  return (
    <SpecialProductCard title="Twoje pakiety">
      {sorted.map((subscription: Subscription) => (
        <SingleSubscription key={subscription.id} subscription={subscription} />
      ))}
    </SpecialProductCard>
  )
}

const groupSubscription = (subscriptions: Subscription[]): GroupedSubscriptions =>
  subscriptions.reduce(
    (acc, subscription) => {
      if (SubscriptionStatusesToShows.includes(subscription.status)) {
        return { ...acc, [subscription.status]: [...acc[subscription.status], subscription] }
      }

      return acc
    },
    { confirmed: [], initial: [], completed: [] },
  )

export const SubscriptionStatusesToShows = ['initial', 'confirmed', 'completed']

import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { SpecialProductRowPaymentButton } from '@modules/special-products/special-product-row-payment-button'
import { Subscription } from '@models/client'
import { StatusVariant } from '@modules/special-products/special-product-status'
import { SpecialProductRowStatus } from '@modules/special-products/special-product-row-status'
import { SubscriptionAgreements } from '@modules/special-products/subscriptions/subscription-agreements'
import { getSubscriptionName } from '@modules/special-products/subscriptions/utils'

interface Props {
  subscription: Subscription
}

export const SubscriptionExpandableRowHeader = ({ subscription }: Props): JSX.Element => {
  const isInitialStatus = subscription.status === 'initial'

  return (
    <div
      className={clsx(
        'py-3 px-2 px-sm-0 d-flex flex-column flex-sm-row align-items-sm-center justify-content-between',
        { 'opacity-50': subscription.status === 'completed' },
      )}
    >
      <SpecialProductRowStatus
        wrapperClassName="d-block d-md-none"
        productStatus={StatusVariant[subscription.status]}
        productStatusText={subscription.status_display}
        title="Status pakietu:"
      />
      <div className="px-2 mb-3 mb-sm-0">
        <IconWithText
          iconClassName="uil-check-square me-2 pt-1 font-size-lg lh-1 align-self-start"
          text={
            <span>
              {getSubscriptionName(subscription)} <span className="fw-light">({subscription.number})</span>
            </span>
          }
        />
        <IconWithText
          className="mt-1"
          iconClassName="me-2 font-size-lg lh-1 uil-tag-alt"
          text={
            <span>
              Aktywnych kodów:{' '}
              <strong className={clsx({ 'text-success': !isInitialStatus })}>
                {isInitialStatus ? 0 : subscription.codes_available}
              </strong>{' '}
              <span className="fw-light">/ {subscription.codes_total}</span>
            </span>
          }
        />
      </div>
      {isInitialStatus && (
        <SpecialProductRowPaymentButton
          amount={subscription.required_payment}
          productUrls={subscription.urls}
          agreements={({ paymentMethod, onProductAgreementsChange }) => (
            <SubscriptionAgreements
              subscription={subscription}
              paymentMethod={paymentMethod}
              onProductAgreementsChange={onProductAgreementsChange}
            />
          )}
        />
      )}
    </div>
  )
}

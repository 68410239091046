import * as React from 'react'
import { SubscriptionList } from '@modules/special-products/subscriptions/subscription-list'
import { SubscriptionDetails } from '@models/client'
import { SubscriptionExpandableRowContentAdditionalOptions } from '@modules/special-products/subscriptions/expandable/subscription-expandable-row-content-additional-options'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionExpandableRowContent = ({ subscriptionDetails }: Props): JSX.Element => (
  <div className="pb-3">
    {(subscriptionDetails.allow_change_client_data ||
      subscriptionDetails.allow_subscription_code_localization_convert) && (
      <SubscriptionExpandableRowContentAdditionalOptions subscriptionDetails={subscriptionDetails} />
    )}
    <SubscriptionList codes={subscriptionDetails.codes} subscriptionType={subscriptionDetails.type} />
    {/*<IconWithText*/}
    {/*  text="Pobierz fakturę końcową"*/}
    {/*  textClassName="font-size-sm"*/}
    {/*  className="opacity-50 pe-none mt-3"*/}
    {/*  iconClassName="font-size-xl me-2 uil-import lh-1"*/}
    {/*/>*/}
  </div>
)
